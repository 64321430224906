import logoImage from 'assets/images/logo.png';
import basicTheme from '_common/constants/theme';
import { PAYMENT_TYPES } from '_common/constants/common';

export const TRACKING_EVENT_TYPES = {
  ORDER_RETURN_CREATED: 'ORDER_RETURN_CREATED',
  CARRIER_COLLECTED: 'CARRIER_COLLECTED',
  BACK_AT_WAREHOUSE: 'BACK_AT_WAREHOUSE',
};

export const PRODUCT_NAME = 'RETURNS_PORTAL';
export const defaultTheme = {
  ...basicTheme,
  assets: { logoImage },
  config: {
    css: {
      accentColor: '#DC1928',
    },
    consumerText: {},
    termsAndConditionsLink: '',
  },
};
export const DEFAULT_PAYMENT_TYPE = PAYMENT_TYPES.CONSUMER_PAID;
export const IS_INTEGRATED_JOURNEY_AVAILABLE = false;
export const DEFAULT_CURRENCY_NAME = 'GBP';
export const MERCHANT_WITH_CUSTOM_PRINT_IN_STORE_BEHAVIOUR = 'AP';
