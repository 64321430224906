/* eslint camelcase: 0 */

import { action, observable, runInAction } from 'mobx';
import { localStore as storage, PDF_LABEL_URL } from 'storage';

import {
  LABEL_POLLER_ATTEMPTS,
  LABEL_POLLER_INTERVAL,
} from '_common/constants/timeout';
import AusPostServices from '../services';
import { CommonActions } from '_common/actions';
import amplitude from '_common/utils/amplitude';
import { ICreateOrderConfig, IOrderCreationStatus } from 'types/order';
import { IStoreWithCommonActions } from 'types/internal';
import { IDetailsFormFields } from 'types/formFields';
import { EValidation_Errors, TSPResult } from 'types/core';
import { getErrorsOfShipmentValidation } from '../_utils';
import i18nService from '_common/services/i18nService';

/**
 * Observes state of creating labels via aus.post proxy.
 */
class AusPostProxyStore implements IStoreWithCommonActions {
  commonStoresActions: CommonActions;

  registerCommonActions = (commonStoresActions: CommonActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @observable
  pdfLabelURL = storage.get(PDF_LABEL_URL);

  @observable
  shipmentCreationResponse = null;

  @observable
  consumerPaymentWasSuccessful: boolean = false;

  @observable
  orderWasSubmitted: boolean = false;

  securePayData: TSPResult | null = null;

  @action
  resetStore = () => {
    this.shipmentCreationResponse = null;
    this.consumerPaymentWasSuccessful = false;
    this.orderWasSubmitted = false;
    this.securePayData = null;
  };

  @action
  createShipment = (
    config: ICreateOrderConfig,
    company: string
  ): Promise<void> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      let bookingId;
      try {
        // Shipment creation step.
        const shipmentCreationResponse = await AusPostServices.proxyService.createShipments(
          { ...config }
        );
        bookingId = shipmentCreationResponse.bookingId;
      } catch (e) {
        return reject(e);
      }

      // Multiple attempts to get shipment data.
      const poller = async (currentIndex): Promise<void> => {
        if (currentIndex >= LABEL_POLLER_ATTEMPTS) {
          amplitude.logEventWithOrganisationAndUrl('Return creation failed');
          return reject(new Error(i18nService.t('success:failedSubtitle')));
        }

        try {
          // Get shipment data.
          const detailedShipment: IOrderCreationStatus = await AusPostServices.proxyService.getShipments(
            bookingId
          );

          if (detailedShipment.status === 'COMPLETE - ORDER CREATED') {
            const { pdfLabelUrl } = detailedShipment;
            runInAction(() => {
              this.pdfLabelURL = pdfLabelUrl;
              this.shipmentCreationResponse = detailedShipment;
              this.orderWasSubmitted = true;
              storage.set(PDF_LABEL_URL, pdfLabelUrl);
            });
            amplitude.logEventWithOrganisationAndUrl('Return created');
            process.env.NODE_ENV === 'production' &&
              amplitude.changeProp('add', 'returns completed', 1);
            company && this.commonStoresActions.validateSession(company, true);
            return resolve();
          }
          /** next tick */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        } catch {
          /** In error case also re-trying. */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        }
      };

      poller(0);
    });

  @action
  validateAddress = async (
    detailsFormFields: IDetailsFormFields
  ): Promise<void> => {
    try {
      const mappedAddress = AusPostServices.proxyService.mapFrontAddressToBack(
        detailsFormFields
      );
      const validationResult = await AusPostServices.proxyService.validateAddress(
        mappedAddress
      );
      const { found } = validationResult;
      if (found) {
        this.commonStoresActions.saveDetailsFormToStorage();
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error_type: EValidation_Errors.INVALID_ADDRESS,
      });
    } catch (e) {
      console.error('Validate address error:', e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error_type: EValidation_Errors.INVALID_ADDRESS,
      });
    }
  };

  @action
  validateShipment = async (config: ICreateOrderConfig): Promise<void> => {
    try {
      await AusPostServices.proxyService.validateShipment({
        ...config,
      });
      return Promise.resolve();
    } catch (e) {
      console.error('Validate shipment error:', e);
      const shipmentError = getErrorsOfShipmentValidation(e);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: shipmentError,
        error_type: EValidation_Errors.INVALID_SHIPMENT,
      });
    }
  };

  @action
  setConsumerPaymentWasSuccessful = (paymentData: TSPResult) => {
    this.securePayData = paymentData;
    this.consumerPaymentWasSuccessful = true;
  };
}

export default AusPostProxyStore;
