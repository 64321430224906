import * as qs from 'qs';
import createClient from '_common/api/clients/clientFactory';
import apiEndpoins from '_common/api/endpoints';
import { localStore as storage, TOKEN_KEY } from 'storage';
import {
  RETURNS_PORTAL_SERVICE_POSTFIX,
  ROOT_ORG_ID,
} from '_common/constants/common';
import { IProxyService } from 'types/whitelabelTypes';
import { MERCHANT_ORGANISATION } from '_common/utils';

const serviceLayerClient = (accountNumber: string) =>
  createClient({
    baseUrl: apiEndpoins.LEGACY_DODDLE_API_URL,
    headers: {
      'account-number': accountNumber,
      Authorization: `Bearer ${storage.get(TOKEN_KEY)}`,
    },
  });

const proxyService: IProxyService = {
  mapFrontAddressToBack: values => ({
    suburb: values.city,
    state: values.state,
    postcode: values.postcode,
  }),
  createReturn: async function createReturn(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/create-return`,
      body
    );
    return res.data;
  },
  createShipments: async function createShipments(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/create-order`,
      body
    );
    return res.data;
  },
  getShipments: async function getShipments(id) {
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/order-status/${id}?ts=${Date.now()}`
    );
    return res.data;
  },
  validateAddress: async function validateAddress(address, accountNumber) {
    const queryAddress = qs.stringify({
      ...address,
      accountNumber,
      companyId: MERCHANT_ORGANISATION,
    });
    const res = await serviceLayerClient(accountNumber).get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/validate-address?${queryAddress}`
    );
    return res.data;
  },
  validateShipment: async function validateShipment(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service/${ROOT_ORG_ID}/validate-order`,
      body
    );
    return res.data;
  },
};

export default proxyService;
