import proxyService from './ausPostProxyService';
import { TRACKING_EVENT_TYPES } from '../_constants';

const trackingEventChecker = eventId => TRACKING_EVENT_TYPES[eventId];

const getWhitelabelRoutes = () => [];

export default {
  proxyService,
  trackingEventChecker,
  getWhitelabelRoutes,
};
