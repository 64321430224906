import { action, computed, observable, ObservableMap } from 'mobx';
import { sessionStore, MOBX_STORES } from 'storage';
import {
  getMerchantFromUrl,
  extractReasonCommentFromProduct,
} from '_common/utils';
import { CommonActions } from '_common/actions';
import amplitude from '_common/utils/amplitude';
import { IReason } from 'types/company';
import { IMappedPurchaseOrderLine } from 'types/order';
import { IStoreWithCommonActions } from 'types/internal';
import { detailsFormFields } from '_common/constants/stores';

const ITEMS_AMPLITUDE_EVENTS = {
  ADDED: 'Item added to return list',
};

class DetailsPageStore implements IStoreWithCommonActions {
  static FORM_FIELDS = detailsFormFields;

  commonStoresActions: CommonActions;

  @observable
  formFields = { ...DetailsPageStore.FORM_FIELDS };

  @observable
  showFormErrorMessage: string | false = false;

  @observable
  returningProductId: string | null = null;

  @observable
  selectedReturnReasonsForProduct: ObservableMap<
    string,
    IReason
  > = observable.map();

  @observable
  selectedReturnCommentForProduct: ObservableMap<
    string,
    string
  > = observable.map();

  registerCommonActions = (commonStoresActions: CommonActions) => {
    this.commonStoresActions = commonStoresActions;
    commonStoresActions.validateSession(getMerchantFromUrl());
    this.formFields = {
      ...this.formFields,
      ...sessionStore.get(MOBX_STORES.DetailsPageStore),
    };
  };

  @computed
  get isAnyProductSelectedForReturn() {
    return (
      this.selectedReturnReasonsForProduct.size > 0 ||
      this.selectedReturnCommentForProduct.size > 0
    );
  }

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = typeof value === 'string' ? value.trim() : value;
  };

  saveFormToStorage() {
    this.commonStoresActions.saveToStorage(
      MOBX_STORES.DetailsPageStore,
      this.formFields
    );
  }

  @action
  enableFormErrorMessage = (error: string | false) => {
    this.showFormErrorMessage = error;
  };

  @action
  resetStore = () => {
    this.formFields = { ...DetailsPageStore.FORM_FIELDS };
    this.selectedReturnReasonsForProduct = observable.map();
    this.selectedReturnCommentForProduct = observable.map();
  };

  @action
  setReturningProductId = (productId: string | null) => {
    if (productId === null) {
      return (this.returningProductId = null);
    }

    if (this.returningProductId === productId) {
      this.returningProductId = null;
    } else {
      amplitude.logEventWithOrganisationAndUrl(ITEMS_AMPLITUDE_EVENTS.ADDED, {
        returningProductId: productId,
      });
      this.returningProductId = productId;
    }
  };

  @action
  setSelectedReturnReasonsForProduct = (
    product: IMappedPurchaseOrderLine,
    returnReason: IReason
  ) => {
    this.selectedReturnReasonsForProduct.set(product.productId, returnReason);
    amplitude.logEventWithOrganisationAndUrl(
      'Selected return reason for product',
      { returningProductId: product, returnReason: returnReason.description }
    );
  };

  @action
  setSelectedReturnCommentForProduct = (
    product: IMappedPurchaseOrderLine,
    comment: string
  ) => {
    this.selectedReturnCommentForProduct.set(product.productId, comment);
    amplitude.logEventWithOrganisationAndUrl(
      'Selected return comment for product',
      { returningProductId: product, comment }
    );
  };

  @action
  deleteSelectedReturnCommentAndReasonForProduct = (productId: string) => {
    this.selectedReturnCommentForProduct.delete(productId);
    this.selectedReturnReasonsForProduct.delete(productId);
  };

  populateOrderWithReturnReasonComment = (
    order: IMappedPurchaseOrderLine
  ): IMappedPurchaseOrderLine => {
    const updatedOrder = extractReasonCommentFromProduct(
      order,
      this.selectedReturnReasonsForProduct.get(order.productId),
      this.selectedReturnCommentForProduct.get(order.productId)
    );
    return updatedOrder;
  };
}

export default DetailsPageStore;
