import { LOCATIONS_MAPPER } from 'pages/locate/utils/locationUtils';
import { IStore } from 'types/store';
import i18nService from '_common/services/i18nService';
import { getMerchantFromUrl } from '_common/utils';
import { MERCHANT_WITH_CUSTOM_PRINT_IN_STORE_BEHAVIOUR } from '../_constants';

export const convertFromAddress = ({
  postcode,
  phoneNumber,
  email,
  fullName,
  addressLine1,
  addressLine2,
  city,
  state,
}) => ({
  postcode,
  phoneNumber,
  email,
  name: fullName,
  line1: addressLine1,
  line2: addressLine2,
  town: city,
  area: state,
});

export interface IAusPostIntegratedOrder {
  postcode?: string;
  phoneNumber?: string;
  email?: string;
  name?: string;
  line1?: string;
  line2?: string;
  town?: string;
  area?: string;
}

export const convertReturns = ({
  purchaseDate,
  returnReason,
  returnReasonCode,
  confirmTerms,
  returnItems,
  reasonComments,
  returnsAuthorisationNumber,
  isIntegrated,
}) => ({
  purchaseDate,
  returnReason,
  returnReasonCode,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  ...(isIntegrated && {
    returnItems: `${returnItems}`,
  }),
});

const convertAddressForMap = ({
  postcode,
  line1,
  line2,
  town,
  area,
}: IAusPostIntegratedOrder) => ({
  addressLine1: line1,
  addressLine2: line2,
  city: town,
  postcode,
  state: area,
});

const assetsForStoresEnabled = true;

const AP_ERROR_MAPPINGS = {
  'lines[0]': 'Address line 1',
  'lines[1]': 'Address line 2',
  suburb: 'City/Suburb',
  name: 'Full name',
};

const dynamicDot = (str: string): string => (str.slice(-1) === '.' ? '' : '.');

const stringHasText = (str: string, text: string): boolean =>
  str?.includes(text);

const appendToString = (str, text) => `${str}${dynamicDot(str)} ${text}`;

export const getErrorsOfShipmentValidation = error => {
  const data = error.response?.data?.errors;
  if (!data || !Array.isArray(data) || !data[0])
    return i18nService.t('details:shipmentInvalid');

  const errorFromAP = data[0];

  // errors from AP side are a bit inconsistent and contain some weird field names like 'lines[0]', so we need to process them
  const processedError = Object.keys(AP_ERROR_MAPPINGS).reduce(
    (message, knownMapping) => {
      if (stringHasText(message, knownMapping)) {
        return message.replace(knownMapping, AP_ERROR_MAPPINGS[knownMapping]);
      }
      if (stringHasText(message, 'Name longer than 40 characters')) {
        return 'Name must not be longer than 40 characters.';
      }
      return message;
    },
    errorFromAP.message
  );

  return stringHasText(processedError, 'try again')
    ? processedError
    : appendToString(processedError, 'Please try again.');
};

// this is a workaround for AP-specific merchant to NOT show printer icon for HOST locations
// AND/OR to NOT show print-in-store filter option
const checkForPrintInStoreOptionsOverrides = (store?: IStore) => {
  const merchantMatchFromUrl =
    getMerchantFromUrl() === MERCHANT_WITH_CUSTOM_PRINT_IN_STORE_BEHAVIOUR;
  return {
    printIconVisible: !merchantMatchFromUrl
      ? true
      : store?.locationType !== LOCATIONS_MAPPER.OTHER_RETAILERS,
    printInStoreFilterVisible: !merchantMatchFromUrl,
  };
};

export default {
  convertFromAddress,
  convertAddressForMap,
  convertReturns,
  assetsForStoresEnabled,
  getErrorsOfShipmentValidation,
  checkForPrintInStoreOptionsOverrides,
};
