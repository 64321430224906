import {
  ausPostLogoImage,
  facebookIcon,
  linkedinIcon,
  phoneIcon,
  printerIcon,
  twitterIcon,
} from 'assets';
import { TRACKING_EVENT_TYPES } from '_common/whitelabelConfig/AUSTRALIA_POST/_constants';
import { IWhiteLabelUi } from 'types/whitelabelTypes';
import i18nService from '_common/services/i18nService';
import {
  EDETAILS_ADDRESS_FIELD,
  EAPP_LOCALES,
  EPrintOptions,
} from 'types/core';
import {
  validateOnSpecialCharacters,
  validatePhoneNumber,
} from '_common/utils/validationUtils';
import { ALLOWED_DATEPICKER_FORMATS } from '_common/constants/date';

const config: IWhiteLabelUi = {
  common: {
    carrierLogo: {
      src: ausPostLogoImage,
      alt: 'Easy returns by Australia Post',
      dimensions: {
        desktop: {
          width: 300,
          height: 58,
        },
        mobile: {
          width: 260,
          height: 45,
        },
      },
    },
    countryCode: 'AU',
    availableLanguages: [EAPP_LOCALES.EN],
    isPrinterOptionDisabled: false,
    header: {
      showCarrierLogo: true,
      centeredLogo: false,
    },
    footer: {
      isHidden: false,
      showCarrierLogo: true,
      logoStacked: true,
      termsAndConditions: {
        href:
          'https://auspost.com.au/terms-conditions/general-terms-conditions',
        label: 'footerTermsLabel', // withTranslation
      },
      privacy: {
        href: 'https://auspost.com.au/privacy',
        label: 'footerPrivacyLabel', // withTranslation
      },
      socialLinks: [
        {
          href: 'https://www.facebook.com/australiapost',
          src: facebookIcon,
          alt: 'facebook',
        },
        {
          href: 'https://twitter.com/auspost',
          src: twitterIcon,
          alt: 'twitter',
        },
        {
          href: 'https://www.linkedin.com/company/australia-post/',
          src: linkedinIcon,
          alt: 'linkedin',
        },
      ],
    },
    defaultMapCenter: {
      lat: -37.80205068,
      lng: 144.95724385,
    },
    isConsumerTextEnabled: (isIntegrated: boolean) => isIntegrated,
  },
  pages: {
    printOption: {
      [EPrintOptions.PAPERLESS]: {
        title: 'printPaperlessTitle', // withTranslation
        nearestLocationText: 'printPaperlessLocation', // withTranslation
        imageSize: 60,
        icon: phoneIcon,
        points: [
          { icon: phoneIcon, text: 'printPaperlessPoint1' }, // withTranslation
          {
            icon: phoneIcon,
            text: 'printPaperlessPoint2', // withTranslation
            includes: [
              { text: 'Post offices' },
              { text: 'Retail partners', isNew: true },
            ],
          },
        ],
      },
      [EPrintOptions.PRINT_OWN]: {
        title: 'printOwnTitle', // withTranslation
        nearestLocationText: 'printOwnLocation', // withTranslation
        imageSize: 100,
        icon: printerIcon,
        points: [
          { icon: printerIcon, text: 'printOwnPoint1' }, // withTranslation
          {
            icon: printerIcon,
            text: 'printOwnPoint2', // withTranslation
            includes: [
              { text: 'Post offices' },
              { text: 'Street Posting Box' },
              { text: 'Retail partners', isNew: true },
            ],
          },
        ],
      },
    },
    start: {
      titleText: 'titleText', // withTranslation
      subTitleText: 'subTitleText', // withTranslation
      getOrderIdRegex: () => ({
        // eslint-disable-next-line no-useless-escape
        regexValue: /([}{\[\]<>!$%^*])/,
        errorText: i18nService.t('wl:startError'), // withTranslation
      }),
      returnIsFreeText: 'startFree', // withTranslation
      ranPlaceholder: 'startPlaceholder', // withTranslation
      costText: 'startCost', // withTranslation
    },
    details: {
      dateFormates: ALLOWED_DATEPICKER_FORMATS,
      orderedFiledNames: [
        [EDETAILS_ADDRESS_FIELD.PHONE],
        [EDETAILS_ADDRESS_FIELD.ADDRESS1, EDETAILS_ADDRESS_FIELD.ADDRESS2],
        [EDETAILS_ADDRESS_FIELD.CITY, EDETAILS_ADDRESS_FIELD.STATE],
        [EDETAILS_ADDRESS_FIELD.ZIP],
      ],
      getStateLabel: item => item.value,
      textValues: {
        purchaseDateLabel: 'detailsPurchaseDate', // withTranslation
        purchaseDatePlaceholder: 'detailsPurchaseDatePlaceholder', // withTranslation
        purchaseDateRequiredErr: 'detailsPurchaseDateRequiredErr', // withTranslation
        returnReasonRequiredErr: 'detailsReturnReasonRequiredErr', // withTranslation
        cityLabel: 'detailsCity', // withTranslation
        cityPlaceholder: 'detailsCityPlaceholder', // withTranslation
        address1Label: 'address1Label', // withTranslation
        address2Label: 'address2Label', // withTranslation
        address1Placeholder: 'address1Placeholder', // withTranslation
        address2Placeholder: 'address2Placeholder', // withTranslation
        areaLabel: 'detailsArea', // withTranslation
        areaPlaceholder: 'detailsAreaPlaceholder', // withTranslation
        isZipVisible: true,
        zipText: 'detailsZip', // withTranslation
        zipLength: 4,
        zipPlaceholder: 'detailsZipPlaceholder', // withTranslation
        tcUrl:
          'https://auspost.com.au/content/dam/auspost_corp/media/documents/easy-returns-terms-and-conditions.pdf',
        countryStates: [
          { value: 'ACT', label: 'Australian Capital Territory' },
          { value: 'NT', label: 'Northern Territory' },
          { value: 'NSW', label: 'New South Wales' },
          { value: 'QLD', label: 'Queensland' },
          { value: 'SA', label: 'South Australia' },
          { value: 'TAS', label: 'Tasmania' },
          { value: 'VIC', label: 'Victoria' },
          { value: 'WA', label: 'Western Australia' },
        ],
        addressInvalidMsg: 'detailsAdressError', // withTranslation
      },
      rules: {
        fullName: [
          {
            required: true,
            message: i18nService.t('wl:detailsFullnameReq'), // withTranslation
          },
          {
            pattern: /^([a-z]+(-[a-z]+)*\s*)+$/i,
            message: i18nService.t('wl:detailsFullnameErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        zipCode: [
          {
            required: true,
            message: `${i18nService.t('wl:detailsZip')} ${i18nService.t(
              'details:isRequired'
            )}.`,
          },
          {
            pattern: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
            message: i18nService.t('wl:detailsZipError'),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine1: [
          {
            required: true,
            message: i18nService.t('wl:detailsAddressReq'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine2: [
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        city: [
          {
            required: true,
            message: i18nService.t('wl:detailsCityReq'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        phoneNumber: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('details:mobileError'),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
          {
            validator: validatePhoneNumber, // TODO check if this is valid for USPS
          },
        ],
      },
      noReasonsText: 'detailsNoReasons', // withTranslation
      noActiveReasonsAllowed: false,
      integratedReasonCommentsEnabled: false,
      reasonCommentsRequiredText: 'detailsCommentError', // withTranslation
      wrapReasonCodeText: false,
    },
    tracking: {
      trackersList: [
        {
          type: TRACKING_EVENT_TYPES.ORDER_RETURN_CREATED,
          descriptionKey: 'wl:trackingCreated',
        },
        {
          type: TRACKING_EVENT_TYPES.CARRIER_COLLECTED,
          descriptionKey: 'wl:trackingCollected',
        },
        {
          type: TRACKING_EVENT_TYPES.BACK_AT_WAREHOUSE,
          descriptionKey: 'wl:trackingBack',
        },
      ],
    },
    success: {
      textValues: {
        successSearchPlaceholder: 'successPlaceholder', // withTranslation
      },
      distanceUnit: 'km',
      storeLogosEnabled: true,
      returnSubtitlePaperless: 'success:returnSubtitlePaperless',
      returnSubtitleOwnLabel: () => 'success:returnSubtitleOwnLabel',
      pdfDownloadEnabled: true,
    },
  },
};

export default config;
